import * as React from "react";
import "./Faq.css";
// import Header2 from "../../components/header/Header2";
import { Container, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import imgFaq from "../../../assets/images/faq-page.png";
import { BackDropEffect } from '../../../components/backDropEffect/BackDropEffect'
import BackLink from '../../../components/backLink/BackLink'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';


export default function CustomizedAccordions() {

  const StyledAccordion = styled(Accordion)({
    boxShadow: "none",
    border: "1px solid rgba(0, 0, 0, 0.20)",
    borderRadius: "10px",
    marginLeft: 0,
    marginRight: 0,
    "&:first-of-type": {
      borderRadius: 10,
    },
    "&:last-of-type": {
      borderRadius: 10,
    },
    "&:before": {
      display: "none",
    },
  })

  const [expanded, setExpanded] = React.useState(1);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const faqList = [
    {
      index: 1,
      question: "How realistic is my score?",
      answer:
        "The PFScores methodology used to generate a score follows the financial needs analysis approach recommended by the Certified Financial Planner Board of Standards. The accuracy of your score totally depends on the accuracy of the data you enter.",
      expanded: true,
    },
    {
      index: 2,
      question: "How to login?",
      answer:
        "New users can create an account by entering in a new username and password. Users can also login using existing usernames and passwords they already have with Google.",
      expanded: false,
    },
    {
      index: 3,
      question: "How do I fill in the data boxes?",
      answer:
        "PFScores prefills most data boxes using averages. Pre Filled boxes are highlighted in green. Users can simply enter in their own data or use the “-“ and “+” signs to increase or decrease the number. Boxes are then white. ",
      expanded: false,
    },
    {
      index: 4,
      question: "Is a score and report financial advice?",
      answer:
        "No. PFScores does not give advice. Please read the PFScores Disclosure statement for full details. Users seeking financial advice should engage a licensed financial planner.",
      expanded: false,
    },
    {
      index: 5,
      question: "Is my data secure?",
      answer:
        "PFScores stores all user data in the cloud using AES 256-bit encryption. PFScores does not ask for or accept social security numbers or any financial account numbers, logins, or passwords.",
      expanded: false,
    },
    {
      index: 6,
      question: "Does PFScores sell products?",
      answer: "No.",
      expanded: false,
    },
    {
      index: 7,
      question: "How do I delete my account?",
      answer:
        "There is a Delete button on the update account information screens. You must login to use this button.",
      expanded: false,
    },
    {
      index: 8,
      question: "Are there restrictions on users?",
      answer:
        "Yes, users need to be both 18 years old and up and a US resident.",
      expanded: false,
    },
    {
      index: 9,
      question: "Does PFScores sell my data?",
      answer:
        "PFScores does not sell any financial data linked to a specific user. ",
      expanded: false,
    },
    {
      index: 10,
      question: "How do I save my data?",
      answer:
        "User data is periodically saved automatically as the user runs the app. ",
      expanded: false,
    },
    {
      index: 11,
      question: "Do I need to complete the app in one use?",
      answer:
        "No. As your data is saved, users can resume at any time to complete the app.",
      expanded: false,
    },
    {
      index: 12,
      question: "Can I run the app multiple times?",
      answer:
        "Yes. Users are encouraged to rerun the app as often as they want. ",
      expanded: false,
    },
    {
      index: 13,
      question: "Can a user submit comments?",
      answer: "Yes. Click on the Comments button.",
      expanded: false,
    },
    {
      index: 14,
      question: "Does PFScores have customer service?",
      answer:
        "No. PFScores will continuously update these FAQs as we receive comments.",
      expanded: false,
    },
  ];

  return (
    <>

      <Container maxWidth="lg">
        <BackDropEffect />
        <BackLink />

        <Grid container spacing={2}>
          <Grid item sx={{ display: { xs: 'none', md: 'block' } }} md={4}>
            <img src={imgFaq} alt="FAQ" />
          </Grid>
          <Grid item xs={12} md={8} sx={{ marginBottom: "100px" }}>
            <Typography variant="h1">
              Frequently Asked Questions
            </Typography>
            {faqList.map((faq) => (
              <StyledAccordion
                expanded={expanded === faq.index}
                onChange={handleChange(faq.index)}

              >
                <AccordionSummary
                  aria-controls={
                    faq.index
                      ? "panel" + faq.index + "d-content"
                      : "panel0d-content"
                  }
                  id="panel1d-header"
                  expandIcon={expanded === faq.index ?
                    <>
                      <svg width={0} height={0}>
                        <linearGradient id="linearColors" x1="0" y1="10" x2="20" y2="10" gradientUnits="userSpaceOnUse">
                          <stop stopColor="#26D878" />
                          <stop offset="1" stopColor="#50EFB6" />
                        </linearGradient>
                      </svg>
                      <RemoveCircleIcon sx={{ fill: "url(#linearColors)" }} />
                    </>
                    : <>
                      <svg width={0} height={0}>
                        <linearGradient id="linearColors" x1="0" y1="10" x2="20" y2="10" gradientUnits="userSpaceOnUse">
                          <stop stopColor="#26D878" />
                          <stop offset="1" stopColor="#50EFB6" />
                        </linearGradient>
                      </svg>
                      <AddCircleIcon sx={{ fill: "url(#linearColors)" }} />
                    </>}
                  sx={{ flexDirection: "row-reverse" }}
                >
                  <Typography sx={{ marginLeft: "10px" }} variant="h4">{faq.question}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body1">{faq.answer}</Typography>
                </AccordionDetails>
              </StyledAccordion>
            ))}
          </Grid>
        </Grid>
      </Container>

    </>
  );
}
